html, body, #container {
    width: 100%;
    height: 100%;
    margin: 0;
}

.ms-Dialog-subText {
    white-space: pre-wrap;
}
/* hack*/
#container > div { 
    height: 100%;
}

button {
    background-color: #000;
}